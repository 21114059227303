import { template as template_d7ed14ca36b24b02b2d4fa83ece2ac58 } from "@ember/template-compiler";
const FKText = template_d7ed14ca36b24b02b2d4fa83ece2ac58(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
