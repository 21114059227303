import { template as template_97a530b3c93048dd8da6297064fc04da } from "@ember/template-compiler";
const SidebarSectionMessage = template_97a530b3c93048dd8da6297064fc04da(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
