import { template as template_77cfcec3399848eea42513749c782a78 } from "@ember/template-compiler";
const FKLabel = template_77cfcec3399848eea42513749c782a78(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
