import { template as template_0e1d7a9203c246e891655dad3c3c58af } from "@ember/template-compiler";
const FKControlMenuContainer = template_0e1d7a9203c246e891655dad3c3c58af(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
